<template>
    <div>
        <b-row class="align-items-center ml-1 mr-1">
            <b-col>
                <div style="font-size: 30px;">{{this.profile.user_data.user_name}}</div>
                <div style="font-size: 16px; ">{{this.profile.user_data.email}}</div>
            </b-col>

           
            <b-col style="max-width:200px">
                <div style="font-size: 14px;  font-weight: 600; text-align:right">
                    <span v-bind:class="{ dotActive: this.profile.user_data.status == 'Active', dotInactive: this.profile.user_data.status == 'Inactive' }"> </span>{{trans('fp-user',172)}} <br>
                    <span class="font-weight-300">{{this.profile.user_data.status}}</span>
                </div>
            </b-col>

            <b-col cols="4">
                <div style="text-align:right">
                    <div style="text-align:right">
                        <span>{{profile.user_data.role}}</span>
                        <!--<span style="cursor:pointer" @click="user_data.id_user_const_role == 1 ? changeUser() : ''">{{profile.user_data.role}}</span>
                        <b-icon style="cursor:pointer" v-if="user_data.id_user_const_role == 1" @click="changeUser()" icon="pencil-fill" class="text-body list-button-icon mt-1 ml-2 align-top"></b-icon>    -->                 
                    </div>     
                </div>
            </b-col>
        </b-row>
        <settings-users-profile-status-bar-role-change v-if="mUserChange.show" :show.sync="mUserChange.show" :args="mUserChange.args" :return.sync="role" :filters="filters" />
    </div>
</template>
<script>
import {} from "bootstrap-vue";
import axios from "@axios";
import SettingsUsersProfileStatusBarRoleChange from '@app/Settings/Users/Profile/StatusBar/SettingsUsersProfileStatusBarRoleChange.vue';

export default {
    components: {
        SettingsUsersProfileStatusBarRoleChange
    },

    props:["profile"],
    
    created() {
        this.role = this.profile.user_data.role;
        this.user_data = JSON.parse(localStorage.getItem('user'));
    },

    watch:{
        role: function(){
            if(this.role != null){
                this.cprofile.user_data.role = this.role;
            }   
        }
    },

    computed: {
        cprofile:{
            get() { return this.profile },
            set(value) { this.$emit("update:profile", value) }
        }
    },

    data(){
        return{
            mUserChange: { 
                show: false, 
                args: {
                    id_user : null,
                    role: null,
                    id_role: null,
                }, 
                result: 0, 
                return: null 
            },
            filters: null,
            role: null,
            user_data: null
        }
    },

    methods:{
           /* changeUser() {
            this.mUserChange.args.id_user = this.profile.user_data.id;
            this.mUserChange.args.role = this.profile.user_data.role;
            this.mUserChange.show = true;
        }*/
    }
};
</script>

<style scoped>
.dotActive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: #20dc37;
  border-radius: 50%;
  display: inline-block;
}
.dotInactive {
  height: 10px;
  width: 10px;
  margin-right: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
</style>
