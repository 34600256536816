<template>
    <div>
        <b-card>
            <app-view-table :title="trans('location',3)" isTitleTotal :filters.sync="filters" :items="cProfile.locations.items"   :fields="fields" :customColumns="customColumns" :loading="loading_list" :customColumnsHeaders="customHeaders" :isDropdownMenu="false"  :isPerPageSelect="false">

                <template v-slot:head_selected>
                    <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                        <b-form-group class="pl-2">
                            <b-form-checkbox v-model="selected_all_rows" value="1" unchecked-value="0" class="custom-control-primary" @change="selectAll($event)" style="margin-top:5px" size="md" :class="selected_all_rows == '1' ? 'custom-checkbox-checked' : ''"/>
                        </b-form-group>
                    </div>
                </template>

                <template v-slot:selected="cell">
                    <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                        <b-form-group class="pl-2">
                            <b-form-checkbox v-model="cell.data.item.selected" value="1" unchecked-value="0" :key="refreshCheck" @change="select($event, cell.data.item)" class="custom-control-primary" style="margin-top:5px" size="md" :class="cell.data.item.selected == '1' ? 'custom-checkbox-checked' : ''"/>
                            
                        </b-form-group>
                    </div>
                </template>

                <template v-slot:buttons>
                    <app-button v-if="locations_change.length > 0" type="primary" :disabled="(cProfile.locations.items.total_checked + total_changed) == 0" @click="changeVisibilityLocation" class="mb-2" >{{trans('save',175)}}</app-button>
                    <app-button v-if="user_role == 1" type="primary" @click="editOffices" class="mb-2" >{{trans('settings-edit-offices',206)}}</app-button>
                </template>

            </app-view-table>

             <small v-if="(cProfile.locations.items.total_checked + total_changed) == 0" class="ml-1 text-error app-input-error-ellips" data-toggle="tooltip" >{{ trans('minimum-one-location-present-error',191) }}</small> 
        </b-card>
        
        <settings-users-profile-offices-change v-if="mOfficesChange.show" :show.sync="mOfficesChange.show" :args="mOfficesChange.args" :result.sync="mOfficesChange.result" :return.sync="cProfile.locations" />
    </div>  
</template>
<script>
 
// #ID _03-02.00 - PMI 2022-01-26

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';
import moment from "moment";
import SettingsUsersProfileOfficesChange from "./SettingsUsersProfileOfficesChange"

  export default {

    props:["profile"],
    
    components: {
        SettingsUsersProfileOfficesChange
    },

    mounted() {
      this.onCreateThis();
    },

    computed: {
         cProfile: {
            get() { return this.profile },
            set(value) { this.$emit('update:profile', value) }
        }
    },

    data() {
        return {
            customColumns: ["selected"],
            customHeaders: ['selected'],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {             
                    office_names: { type: 'list', changed: 0, values: [], function: "getOffices", parameters: null, widthClass:'app-width-min-350'},           

                },
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type: this.listType,
                date_type: this.dateType,
                offer_date: this.offerDate,
                offer_week: this.offerWeek,
                group_by: this.groupBy,

            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "office_name", label:  this.trans('settings-locations',167), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: true, filtering_name: "office_names"},
                { key: "location_name", label: this.trans('location',3), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true},
            ],

            items: [],

            locations_change: [],

            loading_list: false,
            selected_all_rows: '0',
            userSettings: new UserSettings(),
        
            refreshCheck: 0,

            total_changed: 0,

            mOfficesChange: { show: false, args: {
                id_user: [],
                filters: null,
            }, result: 0, return: null},

            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
            loading_download: false

        };
    },

    methods: {
        
        getList() {
           this.loading_list = true;
           axios
                .post("settings/users/profile/locations/getList", {
                    filters: JSON.stringify(this.filters),
                    id_user: this.cProfile.id
                })
                .then((res) => {                 
                    this.filters.page = res.data.page;
                    this.cProfile.locations.items = res.data.items;
                    this.includeChanges();
                    this.checkSelectedAll();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
             
        },

     
        includeChanges(){

            this.cProfile.locations.items.list.forEach(element => {
                    if(( this.locations_change.indexOf(element.id_location_ref_user)) >= 0){
                        if(element.selected == '1'){
                            element.selected = '0';
                        }else{
                            element.selected = '1';
                        }
                    }
                });
            
        },

        selectAll(value){
            if(this.selected_all_rows == '1'){
                this.cProfile.locations.items.list.forEach(element => {
                    if(element.selected == '0'){
                        this.select(value, element, true);
                    }
                    
                    element.selected = '1';
                });
            }else {
                this.cProfile.locations.items.list.forEach(element => {
                    
                    if(element.selected == '1'){
                        this.select(value, element, true);
                    }
                    element.selected = '0';
                });
            }


            this.refreshCheck++;

        },

        select(value, item, all = false){

            
            if(value == 1){
                ++this.total_changed;
            }else{
                --this.total_changed;
            }
            var index = null;
            if((index = this.locations_change.indexOf(item.id_location_ref_user)) >= 0){
                this.locations_change.splice(index, 1)
            }else{
                this.locations_change.push(item.id_location_ref_user);
            };

            if(!all){
                this.checkSelectedAll();
            }
           
            
        },

        changeVisibilityLocation(){
            this.loading_list = true;
            axios
                .post("settings/users/profile/locations/changeVisibilityLocation", {
                    filters: JSON.stringify(this.filters),
                    id_user: this.cProfile.id,
                    ids_location_ref_user: JSON.stringify(this.locations_change)
                })
                .then((res) => {                 
                    this.filters.page = res.data.list.page;
                    this.cProfile.locations.items = res.data.list.items;
                    this.locations_change = [];
                    this.total_changed = 0;
                    localStorage.setItem("USER_LOCATIONS", JSON.stringify(res.data.user_locations));

                    this.checkSelectedAll();
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        checkSelectedAll(){
            if (this.cProfile.locations.items != undefined && this.cProfile.locations.items.list != undefined) {
                let selected = this.cProfile.locations.items.list.filter(function(value) { 
                    return value.selected == '1'; 
                }).length;

                if(this.cProfile.locations.items.list.length == selected){
                    this.selected_all_rows = '1';
                }else{
                    this.selected_all_rows = '0';
                }
                

            }
        },

        unselect(){

            this.selected_all_rows = false;
            this.cProfile.locations.items.list.forEach(element => {
                element.selected = '0';
            });
            this.cheked_items = 0;
            this.refreshCheck++;

        },


        editOffices(){
            this.mOfficesChange.args.id_user = this.cProfile.id;
            this.mOfficesChange.args.filters = this.filters;
            this.mOfficesChange.show = true;
        },

        onCreateThis() { 
            this.checkSelectedAll();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });


            this.$watch('filters.filtering.office_names.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
           
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('cProfile.locations.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
    opacity: 1 !important;
}
.app-local-ellips {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis; 
   }

.app-local-width{
    width: calc(100% - 32px);
}

</style>
