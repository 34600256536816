<template>
    <div v-if="profile !== null" class="app-navbar-profile-content">  
        <b-navbar sticky class="app-navbar-profile">
            <div class="app-navbar-header">
                <b-row v-if="user_data.id_user_const_role == 1" >
                    <b-col cols="8" class="app-navbar-profile-margin-4"></b-col>
                    <b-col end class="app-navbar-profile-margin-30">
                        <div class="d-flex justify-content-end align-items-center">
                            <div class="font-weight-800 mr-4 mt-1"> {{trans('core-user-profile', 271)}} </div>
                            <b-dropdown variant="link" right>
                                <template #button-content>
                                    <b-icon icon="gear-fill" class="text-secondary" style="width: 17px; height: 17px; margin-right: -2px"></b-icon>
                                </template>
                                <b-dropdown-item :to="{ name: 'settings-user-logs-list', params: { id_user: profile.id }}">{{trans('history',182)}}</b-dropdown-item>
                                <b-dropdown-item @click="clearSettings()" >{{trans('settings-clear-settings',206)}}</b-dropdown-item>
                                <b-dropdown-item @click="sendNotification()" v-if="" >{{trans('fp-notify',172)}}</b-dropdown-item>
                            </b-dropdown>
                            
                            
                        </div>
                    </b-col>
                </b-row>
                <b-row class="align-items-center mb-3">
                    <b-col> 
                    <settings-users-profile-status-bar :profile.sync ="profile" />
                    </b-col>        
                </b-row> 
            </div>
        </b-navbar>
        <div>
            <b-row>
                <b-col md="12" lg="8" class="">
                    <b-card class="mb-3" v-if="!profile.user_data.id_easyflex">
                        <b-row class="app-card-header">
                            <b-col start>
                                <b-alert variant="warning" show class="mb-0">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div>
                                            <div class="d-flex w-100 align-items-center">
                                                <b-icon icon="person-lines-fill" font-scale="4" class="mr-4"></b-icon>                                  
                                                <div class=" ml-3 w-100 align-items-center">           
                                                    <span class="align-middle font-weight-600 text-base">{{trans('data-missing',207)}}</span>  <br/>  
                                                    <span class="text-small">{{trans('user-has-not-set-easyflex-id',207)}}</span>                                   
                                                </div>                                 
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <app-button type="primary" @click="changeEasyflex()">{{trans('add',175)}}</app-button>                              
                                        </div>
                                    </div>
                                </b-alert> 
                            </b-col>
                        </b-row>
                    </b-card>
                    <settings-users-profile-contact-component class="mb-3" :profile.sync="profile" />
                    <b-row class="mb-3">
                        <b-col > 
                            <settings-users-profile-history-component  :profile.sync="profile"/>
                        </b-col>
                    </b-row>
                </b-col>

                <b-col md="12" lg="4" class="mt-3 mt-lg-0 pl-lg-2"> 
                    <settings-users-profile-signature-component :profile.sync="profile"/>
                    <settings-users-profile-locations-list :profile.sync="profile"/>
                </b-col>
            
            </b-row>
        </div>
        <user-profile-notification-send v-if="mNotification.show" :show.sync="mNotification.show" :result.sync="mNotification.result" :args.sync="mNotification.args" :return.sync="profile"/>
        <settings-users-profile-easyflex-change v-if="mEasyflexChange.show" :show.sync="mEasyflexChange.show" :result.sync="mEasyflexChange.result" :args.sync="mEasyflexChange.args" :return.sync="profile"/>
    </div>
</template>

<script>
import axios from "@axios";
import SettingsUsersProfileStatusBar from './StatusBar/SettingsUsersProfileStatusBar.vue';
import SettingsUsersProfileHistoryComponent from './History/SettingsUsersProfileHistoryComponent.vue';
import SettingsUsersProfileLocationsList from './Locations/SettingsUsersProfileLocationsList.vue';
import SettingsUsersProfileSignatureComponent from './Signature/SettingsUsersProfileSignatureComponent.vue';
import SettingsUsersProfileContactComponent from './ContactDetails/SettingsUsersProfileContactComponent.vue';
import SettingsUsersProfileEasyflexChange from './Easyflex/SettingsUsersProfileEasyflexChange.vue';
import UserProfileNotificationSend from './Notification/UserProfileNotificationSend.vue';
import AppAlerts from '@core/scripts/AppAlerts';

export default {
    
    components: {
        SettingsUsersProfileStatusBar,
        SettingsUsersProfileLocationsList,
        SettingsUsersProfileSignatureComponent,
        SettingsUsersProfileHistoryComponent,
        SettingsUsersProfileContactComponent,
        SettingsUsersProfileEasyflexChange,
        UserProfileNotificationSend
       
    },

    created() {
        this.user_data = JSON.parse(localStorage.getItem('user'));
        this.getData();
    },

    watch: {
      "profile.refresh": {
            handler: function(val) {
                if (val > 0) {
                    this.getPersonData();
                }                
            },
        },    
    },

    data() {
        return {
            profile: null,
            id_user: this.$route.params.id_user,
            user_data: null,
            alert_class: new AppAlerts(),
            mNotification: {show: false, args: {id_user: null, message: ''}, result: 0, return: null},
            mEasyflexChange: { show: false, args: {
                id_user:null,
                id_easyflex:null,
            }, result: 0, return: null },
        };
    },

    methods: {
        getData(){

            if(this.id_user != null){
                var link = "settings/users/getProfile/" + this.id_user;
            }else{
                var link = "settings/users/getProfile";
            }


            axios
                .get(link)
                .then((res) => {
                    this.profile = res.data;  
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
            },

            clearSettings(){
             this.alert_class.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {
                if(res == true){
                    axios
                        .post("settings/users/profile/status-bar/clearSettings", {
                            user_id: this.id_user
                        }).catch((error) => {
                            console.error(`error during request: ${error}`);
                        })
                }
             });
        },

        sendNotification(){
            this.mNotification.args.id_user = this.id_user;
            this.mNotification.args.message = '';
            this.mNotification.show = true;
        },

         getPersonData(){

             if(this.id_user != null){
                var link = "settings/users/getPersonData/" + this.id_user;
            }else{
                var link = "settings/users/getPersonData";
            }

            axios
                .get(link)
                .then((res) => {
                    if(res.data.length > 0){
                    this.profile.user_data = res.data[0];  
                    } 
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                });
        },

        changeEasyflex(){
            this.mEasyflexChange.args.id_user = this.id_user;
            this.mEasyflexChange.args.id_easyflex = this.profile.user_data.id_easyflex;
            this.mEasyflexChange.show = true;
        }
    },
};
</script>

<style scoped>
.app-local-alert{
    max-width:500px;
}
</style>
