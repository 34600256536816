<template>
    <div>
        <b-row class="app-component-icon-edit">
            <b-dropdown  variant="link"  no-caret right>
                <template  #button-content>
                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                </template>
                <b-dropdown-item @click="change">{{trans('change',175)}}</b-dropdown-item>
            </b-dropdown>
        </b-row>
        <b-card no-body class="app-card-profile">
               <b-row class="app-card-header">
                    <b-col>
                        <div class="app-card-profile-title">
                            {{trans('settings-additional-information',167)}}
                        </div>
                    </b-col>
                </b-row>
                        
                <b-row class="mt-3">
                    <b-col cols="3">
                        <b-form-group :label="trans('function',3)">
                            <span class="app-local-bold-text"> {{profile.user_data.function_name != null ? profile.user_data.function_name : '-' }} </span>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group :label="trans('cp-account-manager',192)">
                            <span class="app-local-bold-text"> {{profile.user_data.key_account_manager == 1 ? trans('yes-option',3) : trans('no-option',3) }} </span>
                        </b-form-group>
                    </b-col>
                </b-row>
           
        </b-card>
        <settings-users-profile-contact-change v-if="mContactDataChange.show" :show.sync="mContactDataChange.show" :result.sync="mContactDataChange.result" :args.sync="mContactDataChange.args" :return.sync="profile"/>
    </div>
</template>
<script>

import SettingsUsersProfileContactChange from "./SettingsUsersProfileContactChange.vue";

export default {
    components: {
        SettingsUsersProfileContactChange
    },

    props:["profile"],

    data(){
        return{
            mContactDataChange: { show: false, args: {function_name: null, key_account_manager: null}, result: 0, return: null }
        }
    },

    methods:{
        change(){
            this.mContactDataChange.args.id_user = this.profile.id;
            this.mContactDataChange.args.function_name = this.profile.user_data.function_name;
            this.mContactDataChange.args.key_account_manager = this.profile.user_data.key_account_manager;
            this.mContactDataChange.show = true; 
        }
    }


};
</script>

<style scoped>
.app-local-bold-text{
    font-weight:700;
    color:#174066;
}
</style>