<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('settings-additional-change',167)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
               <app-row-left-label :label="trans('function',3)">
                    <app-input v-model="cArgs.function_name" validatorName="Functie" validatorRules="required" mask="lettersE1-30"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('cp-account-manager',192)">
                    <app-check-box v-model="cArgs.key_account_manager" class="custom-control-primary" value="1" unchecked-value="0" size="lg" />
                </app-row-left-label>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";

import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    data() {
        return {
            loading: false,
        };
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },
        cReturn: {
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    methods: {    

        async change() {
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else {
                    this.loading = true;                    
                    axios
                        .post("settings/users/profile/contact-details/change", {
                            user_id: this.cArgs.id_user,
                            function_name: this.cArgs.function_name,
                            key_account_manager: this.cArgs.key_account_manager
                        })
                        .then((res) => {
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.cReturn.user_data = res.data;
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style>

</style>
