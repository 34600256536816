<template>
    <b-card>
        <app-view-table :title="trans('history',182)" :loading="loading_list" :isTitleTotal="false" :filters.sync="filters" :items="cProfile.history.items" v-on:onRowSelected="getProfile" :fields="fields" :isSearch="true" :customColumns="customColumns" :isDropdownMenu="false" class="table-selectable">

            <template v-slot:person="cell">
                {{cell.data.item.person.length > 30 ? cell.data.item.person.substring(0,30) + '...' : cell.data.item.person}} 
            </template>
            <template v-slot:full_description="cell">
                {{cell.data.item.category_name}}, {{cell.data.item.type_name}} {{cell.data.item.description != "" ? ' - '+ cell.data.item.description : ''}} 
            </template>
            
        </app-view-table>

    </b-card>  
</template>
<script>

// #ID _99-02.00 - PDH 2022-01-25

import axios from "@axios";

export default {

    props: ['profile'],
    
    mounted() {
        this.onCreateThis();
    },

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', this.profile) }
        }
    },

    data() {
        return {
            loading_list: false,
            customColumns:["person","full_description"],
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0,

                },
                
                filtering: {},
                search: "",
                status: 1,
                changed: 0,
            },
            fields: [
                { key: "entry_date", label: this.trans('starting-date',182), thStyle: { "min-width": "170px", "max-width": "170px" }, visible: true, filtering: false },
                { key: "person", label: this.trans('history-entry-for',182), thStyle: { "min-width": "220px", "max-width": "250px" }, visible: true, filtering: false },
                { key: "full_description", label: this.trans('content',182), thStyle: { "min-width": "300px", "max-width": "100%" }, tdClass: "table-column-wrap", visible: true, filtering: false},
                
            ],
            

            items: [],
        };
    },


    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/users/profile/history/getList", {
                    filters: JSON.stringify(this.filters),
                    id_user: this.profile.id,
                })
                .then((res) => {
                    this.cProfile.history.items = res.data.items;
                    this.filters.page = res.data.page;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0 && selectedRows[0].type == "applicant"){
                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: selectedRows[0].person_id } });
            }else if(selectedRows.length != 0 && selectedRows[0].type == "client"){
                this.$router.push({ name: "clients-profile-organization", params: { id_client_data_main: selectedRows[0].person_id } });
            }else if(selectedRows.length != 0 && selectedRows[0].type == "location"){
                this.$router.push({ name: "clients-profile-location", params: { id_location_data_main: selectedRows[0].person_id } });
            }else if(selectedRows.length != 0 && selectedRows[0].type == "department"){
                this.$router.push({ name: "clients-profile-department", params: { id_department_data_main: selectedRows[0].person_id } });
            }
        },

        onCreateThis() { 


            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
            this.$watch('profile.history.items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

        }
    },
 
};
</script>
<style>
</style>