<template>
    <div>
        <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('change',175)">
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('settings-role',207)">
                    <app-radio-group v-model="selected_role" :options="roles_list" :stacked="true" />
                </app-row-left-label>
            </b-form>
            
        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
        </b-modal>
    </div>
</template>

<script>
import axios from "@axios";
export default {

    props: ["show", "args", "filters", "return"],

    created(){
        this.selected_role = this.all_roles.indexOf(this.args.role) + 1;
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }
        },
        cReturn:{
            get() { return this.return },
            set(value) { this.$emit('update:return', value) }
        }
    },

    data(){
        return{
            loading: false,
            selected_role: null,
            all_roles: [this.trans('settings-administrator',167), this.trans('settings-hq-employee',206), this.trans('settings-location-employee',206)],
            roles_list: [
                                { value: 1, text: this.trans('settings-administrator',167) },
                                { value: 2, text: this.trans('settings-hq-employee',206) },
                                { value: 3, text: this.trans('settings-location-employee',206) },
                            ],
        }
    },

    methods: {
        closeModal(){
            this.$emit('update:show', false);
        },

        change(){
            this.loading = true;

            axios
                .post('settings/users/profile/status-bar/changeRole', {
                    user_id: this.args.id_user,
                    role_id: parseInt(this.selected_role),
                    filters: JSON.stringify(this.filters)
                })
                .then((res) => {
                    this.cReturn = res.data.role;
                    this.$emit('update:show', false);
                    this.loading = false;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
        }
    }

}
</script>

<style>

</style>