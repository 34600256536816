<template>
    <div>
        <b-card class="mb-3" v-if="profile.user_data.signature == null || profile.user_data.signature == ''">
            <b-row class="app-card-header">
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                       {{trans('settings-signature',167)}}
                    </div>

                    <b-alert variant="warning" show class="mb-0">
                        <div class="font-weight-600 text-base">{{trans('missing-signature',180)}}</div>
                        <div class="text-small">{{trans('settings-create-signature-to-sign',208)}}</div>    
                    </b-alert> 

                </b-col>
            </b-row>
             <b-row class="d-flex align-items-center mt-2">
                <b-col>
                    <app-button type="primary" @click="changeSignature()" >{{trans('settings-add-signature',206)}}</app-button>    
                </b-col>
            </b-row>
        </b-card>
        <b-card class="mb-3" v-else>
            <b-row class="app-card-header">
                <b-col start>
                    <div class="app-card-profile-title mb-3">
                        {{trans('settings-signature',167)}}
                    </div>
                </b-col> 
                <b-col cols="auto" end class="d-flex align-items-center">
                    <div style="min-width:24px; max-width:24px;">
                         <b-spinner v-if="loading" style="width:19px; height:19px; margin-left:2px; margin-top:5px" small></b-spinner>       
                    </div>
                </b-col>

            </b-row>
            <b-row>
                <b-col>
                    <b-alert variant="success" show class="mb-0">
                        <div class="font-weight-600 text-base">{{trans('settings-signature-set',208)}}</div>   
                    </b-alert> 
                </b-col>
            </b-row>
             <b-row class="d-flex align-items-center mt-2">
                <b-col>
                    <p class="text-sm mt-2 mb-0 app-notes-span">
                        <span>{{ profile.user_data.signature_date }} | </span>
                        <span class="app-text-link" @click="changeSignature()">{{trans('change',175)}}</span> |
                        <span class="app-text-link" @click="deleteSignature()">{{trans('remove',175)}}</span>
                    </p>  
                </b-col>
            </b-row>
        </b-card>
    </div>  
</template>
<script>

// #ID _99-02.00 - PDH 2022-01-13

import AppAlerts from '@core/scripts/AppAlerts';
import axios from "@axios";

export default {

    props:["profile"],

    computed: {
        cProfile: {
            get() { return this.profile },
            set() { this.$emit('update:profile', this.profile) }
        }
    },

    data() {
            return {
                alert_class: new AppAlerts(),
                loading: false,
            };
    },
  
    methods: {
        changeSignature() {
            this.$router.push({ name: "user-signature-pad", params: { idUserSignature: this.profile.id, returnType: "user_card", returnIdType: this.profile.id, signatureJson: this.profile.user_data.signature_json} });
        },

        deleteSignature() {
            this.alert_class.openAlertConfirmation(this.trans('delete-line-confirm',177)).then(res => {
                this.loading = true;

                axios
                    .post("settings/users/profile/signature/change", {
                        id_user: this.profile.id,
                        signature: null,
                        signature_json: null    ,
                    })
                    .then((res) => {
                        this.cProfile.user_data = res.data
                        //usunięcie z local storage podpisu usera
                        if(this.profile.id == JSON.parse(localStorage.getItem('user')).id){
                            var user_data = JSON.parse(localStorage.getItem('user'));
                            user_data.signature = res.data.signature;
                            user_data.signature_date = res.data.signature_date;  
                            user_data.signature_json = res.data.signature_json;
                            localStorage.setItem('user', JSON.stringify(user_data));
                        }

                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(`error during request: ${error}`);
                    });
            }); 
        }       
    }
 
};
</script>

<style>


</style>