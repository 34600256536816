<template>
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('change',175)">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-row-left-label :label="trans('user_easyflex',207)">
                    <app-select v-model="cArgs.id_easyflex" :currentError="user_id != null" type="getUsersEasyflex" validatorRules="required" validatorName="Functies"/>
                    <small v-if="user_id != null" class="ml-1 text-error ">{{ trans('id_taken', 208 , {user_name : user_name, user_id : user_id}) }}</small>
                </app-row-left-label>
            </b-form>
        </ValidationObserver>

        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button>
            <app-button type="primary" @click="change" :loading="loading">
                {{trans('save',175)}}
            </app-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationObserver
    },

    props: ["show", "args", "return", "result"],

    created(){
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set() { this.$emit('update:show', false) }, 
        },
        cReturn: {
            get() { return this.return },
            set() { this.$emit('update:return', false) }, 
        },
        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }, 
        },
    },

    watch: {
        "cArgs.id_easyflex": {
            handler: function(val) {
                if(val != null) {
                    this.user_name= val.user_name;
                    this.user_id= val.user_id;
                }    
            },
        },
    },


    data() {
        return {
            loading: false,
            user_name: null,
            user_id: null
        };
    },

    methods: {
        change() {
   
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return false;
                }
                else{
                    this.loading = true;
                    axios
                        .post("settings/users/profile/easyflex/change", {
                            id_user: this.args.id_user,
                            id_easyflex: this.cArgs.id_easyflex.value,
                        })
                        .then((res) => {
                            this.cReturn.user_data = res.data;
                            this.$emit("update:show", false);
                            this.$emit("update:result", 1);
                            this.loading = false;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        });
                }
            })
            
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>

<style scoped>
</style>
